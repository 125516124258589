<!-- 新增教学视频 -->
<template>
<el-form ref="form" :model="video" label-width="100px" size="small">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('标题')">
                <el-input v-model.trim="video.name" placeholder="请输入标题" size="small"></el-input>
            </el-form-item>
            <el-form-item :label="msg('网址')">
                <el-input type="textarea" :rows="4" v-model.trim="video.url" placeholder="请输入网址" size="small"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'VideoEdit',
  data(){
    return{
        video:null,
    }
  }, 
  computed:{
  },
  mounted: function(){
  },
  methods:{
    init(video){
        this.video = video;
    },
    onSubmit(){
        var _this = this;

        if(!this.video.name){
            this.$message(this.msg('请输入标题'));
            return;
        }
        if(!this.video.url){
            this.$message(this.msg('请输入网址'));
            return;
        }

        let url = '';
        if(this.video.id){
            url = this.$kit.api.video.edit;
        }else{
            url = this.$kit.api.video.add;
        }

        this.$axios.post(url,this.video)
            .then((result) =>{
                _this.$message(result.msg);
                if(result.status){
                    _this.$emit("close-dg", true);
                }
            });
    },
  }
}
</script>

<style scoped lang="scss">


</style>
